import Vue from 'vue'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // baseURL: 'http://localhost:8080',
  // baseURL: 'https://api.alpha.factura.green',
  // baseURL: 'https://1-0-7-22-dot-facturagreen-alpha.uc.r.appspot.com',
  baseURL: 'https://api.factura.green',
  //baseURL: 'https://preprod-dot-facturagreen-live.uc.r.appspot.com',
  // baseURL: 'https://feature-kronos-ba-dot-facturagreen-alpha.uc.r.appspot.com',
  //timeout: 1000,
  headers: { 'X-arkeline-sign': 'f3854c370e487f78fa9452c879953acca4faecb31a137a8dcbcf6f6a740ad353038a74034cacd3aefa969a0894c4c1132089ad7bcbf2c4e34f04332415f5e5e4' }
})

Vue.prototype.$http = axiosIns

export default axiosIns